.modal {
  color: black;
  background: rgba(208, 226, 245, 0.5);
}



.modal-header {
    background-color: #ffffff;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.9);
    font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
}

.modal-body {
    background-color: #ffffff;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.9);
    height: 100%;
    overflow-y: auto;
    font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
}

.modal-container {
  display: "flex";
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
}


.modal-icon {
    width: 40px;
    height: 40px;
}

.modal-button {
    color: white;
    background: linear-gradient(to right, rgb(0,0,255), rgb(0,0,200));
    box-sizing: content-box;
    font-size: 16px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    padding: 10px;
    border-radius: 10px;
    border: none;
}



.modal-p {
    padding: 5;
    
}



._modal-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em;
  line-height: 1;
  background: #f6f6f7;
  border: 0;
  box-shadow: 0;
  cursor: pointer;
}

._modal-close-icon {
  width: 25px;
  height: 25px;
  fill: transparent;
  stroke: black;
  stroke-linecap: round;
  stroke-width: 2;
}

.modal-content {
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;

}

.modal-image {
    padding: 5;
    font-size: 12;
    font-style: italic;
}


