body {
    display:flex;
    #root {
	flex-grow: 1;
	display:flex;
	flex-direction: column;
    }
    background: white;
    font-family: Arial, arial, cursive;
    //font-family: Consolas, monaco, monospace;
    font-size: 24px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    //background: #438AF7;
    //rgba(67, 138, 247, 1)
}

header {
    //border: b;
    border-color: gray-300;

}

header-img {
    height: 48px;
    width: 48px;
}

footer {
    background-color: white;
    padding: 20px;
    position: relative;
    font-size: 12px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
}

footer-img {
    height: 48px;
    width: 48px;
}

.footer-box {
}


.navigation {
    color: white;
    background-color: white;
    shadow: smooth;
    display: flex;
}

.navigation-brand {
    color: white;
    background-color: white;
    shadow: smooth;
    display: flex;
}

.navigation-menu {
    color: gray-300;
    background-color: white;
    shadow: smooth;
    font-size: 20px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;

}

.navigation-menu li {
    color: white;
    background-color: white;
    shadow: smooth;

}
.header-item-active {
    color: "#D5933A";
    background-color: white;
    shadow: smooth;
    font-size: 20px;
    font-style: normal;
    font-variant: normal;
    font-weight: 600;


}

.content-container {
    mx: auto;
    lg: px-4 py-4 min-h-screen;
    display: flex;
    md: items-center;
    md: justify-center;
    padding: 10px;
}    

.content-item {
    text: center;
    font-size: 16px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
}    

.content-header {
    text: center;
    font-size: 20px;
    font-style: normal;
    font-variant: normal;
    font-weight: 600;
}    

.content-date {
    text: center;
    padding: 2px;
    font-size: 12px;
    font-style: italic;
    font-variant: normal;
    font-weight: 400;
}
.content-author {
    text: center;
    padding: 2px;
    font-size: 14px;
    font-style: italic;
    font-variant: normal;
    font-weight: 400;
}

.content-box {
    text: center;
    padding: 5px;
    font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
}

.content-right {
    text: right;
    padding: 5px;
    font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
}    

.content-image {
    text: center;
    font-size: 12px;
    font-style: italic;
    font-variant: normal;
    font-weight: 400;
}

.clickable {
    cursor:pointer
}

.side-menu-hidden {
	display:     "hidden"
}


.side-menu {
	height:150px;
	background: white;
	margin-top: -150px;
	background-image: './media/images/pattern-dots.svg';
	background-size:auto 50%;
	background-position: 20px 20px;
	background-repeat:no-repeat;
	position:relative;
}


.side-menu-active {
	height:150px;
	background:white;
	margin-top: 0px ;
	background-image: './media/images/pattern-dots.svg';
	background-size:auto 50%;
	background-position: 20px 20px;
	background-repeat:no-repeat;
	position:relative;
}


.leaflet {
  width: 400px;
  height: 400px;
}

.leaflet-container {
  width: 400px;
  height: 400px;
}



._hide-visual {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.scroll-lock {
  overflow: hidden;
  margin-right: 17px;
}


@media only screen and (max-width: 825px) {
    .brand-logo {
        width: 48px;
        height: 48px;
    }
}


